import { Injectable } from '@angular/core';

declare var google;

@Injectable()
export class JsMapsProvider {
  map: any;
  location;
  geocoder;
  service;
  marker;
  constructor(
  ) {
    this.service = new google.maps.places.AutocompleteService();
    this.geocoder = new google.maps.Geocoder();
  }

  init(location, element, zoom){
    const latLng = new google.maps.LatLng(location.latitude, location.longitude);
    const opts = {
      center: latLng,
      zoom: zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      streetViewControl: false,
      scrollwheel: false,
      mapId : '17d2a34df145a0c'
    };

    return this.map = new google.maps.Map(element.nativeElement, opts);
  }

  addMarker(options, animation) {
    const self = this;
    const icon = {
      url: options.icon, // url
      scaledSize: new google.maps.Size(35, 50), // scaled size
      origin: new google.maps.Point(0,0), // origin
      anchor: new google.maps.Point(0, 0) // anchor
    };
    let _animation = animation ? google.maps.Animation.DROP : null;

    const myLatlng = {lat: options.position.latitude, lng: options.position.longitude};
    this.marker = new google.maps.Marker({
      position: myLatlng,
      map: this.map,
      title: options.title,
      draggable: false,
      animation: _animation
      //'icon': icon
    });
    this.setLocation({
      latitude: options.position.latitude,
      longitude: options.position.longitude
    });
    return this.marker;
  }

  setLocation(position) {
    this.location = position;
  }

  clearMarkers(markers) {
    for (let marker of markers) {
      marker.setMap(null);
    }
    return true;
  }

  inRange(point1, point2) {
    return new Promise((resolve, reject) => {
      if (point1 && point2) {
        const distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(point1), new google.maps.LatLng(point2));
        resolve(distance);
      } else {
        reject(false);
      }
    })
  }
}
