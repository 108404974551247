import { UserInterface } from './../../models/user.interface';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Router } from  '@angular/router';
import {FirebaseService} from "../firebase.service";
import firebase from 'firebase/app';
import 'firebase/auth';
import {Storage} from '@ionic/storage';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  user: any;
  private usersCollection: AngularFirestoreCollection<UserInterface>;
  private users: Observable<UserInterface[]>;
  constructor(private db: AngularFirestore,
              private fb: FirebaseService,
              public  router: Router,
              private storage: Storage
              ) {
    let self = this;
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        self.fb.getDocument('user', user.email).then(
          res => {
            if (res && res['active'] == true) {
              self.storage.set('currentUser', res);
            } else {
              self.logout();
            }
          }
        );
      } else {
        self.logout();
        return null;
      }
    });
  }

  logout() {
    firebase.auth().signOut().then(() => {
      localStorage.clear();
      this.storage.clear();
      this.router.navigateByUrl('/login', { replaceUrl: true });
    });
  }

  getUser(email) {
    return new Promise((resolve, reject) => {
      if (email === '') {
        reject(false);
      }
      const query = this.db.collection('user').doc(email);
      query.get().subscribe(
        result => {
          const id = result.id
          const data = result.data() as {};
          resolve({id, ...data});
        },
        error2 => {
          reject(error2);
        }
      );
    });
  }

  currentUser() {

  }

  async login(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then(
          res => {
            this.getUser(email).then(
              user => {
                this.storage.set('currentUser', user);
                resolve(res);
              }
            );
          },
          err => reject(err));
    });
  }

  async register(email: string, password: string, role, fullname: string) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(email, password)
        .then(
          res => {
            this.db.collection('user').doc(email).set(
              {
                "email": email,
                "role": role,
                "fullname": fullname
              }
            );
            let actionCodeSettings = {
              url: 'https://app.boumopanama.com'
            };
            firebase.auth().currentUser.sendEmailVerification(actionCodeSettings)
              .then(res => {
              })
              .catch(error => {
                console.error(error);
              });
            this.resetPassword(email);
            resolve(res);
          }
        ).catch(
          error => {
            console.error(error);
            reject(error);
          })
    });
  }


  async create(email: string, password: string, values) {
    let user = firebase.auth().currentUser;
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(email, password)
        .then(
          res => {
            firebase.auth().updateCurrentUser(user).then(
              () => {
                this.db.collection('user').doc(email).set(values);
                let actionCodeSettings = {
                  url: 'https://app.boumopanama.com'
                };
                firebase.auth().currentUser.sendEmailVerification(actionCodeSettings)
                .then(res => {
                })
                .catch(error => {
                  console.error(error);
                });
              this.resetPassword(email);
              resolve(res);
              }
            );
          }
        ).catch(
          error => {
            console.error(error);
            reject(error);
          })
    });
  }

  async updateUser(email: string, password = null, userData) {

    return new Promise<any>((resolve, reject) => {
      if (!email) {
        reject('User not found.');
      }
      else if (password) {
        firebase.auth().currentUser.updatePassword(password).then(
          res => {
            this.fb.updateDocument('user', email, userData).then(res => {
              resolve(true);
            }).catch(error => reject(error));
          }
        ).catch(error => {
          if (error.code == 'auth/requires-recent-login') {
            this.logout();
          }
          reject(error);
        });
      }
      else {
        this.fb.updateDocument('user', email, userData).then(res => {
          resolve(true);
        });
      }

    });
  }

  async sendEmailVerification() {
    await firebase.auth().currentUser.sendEmailVerification();
    this.router.navigate(['admin/verify-email']);
  }

  resetPassword(email: string) {
    var auth = firebase.auth();
    let actionCodeSettings = {
      url: 'https://app.boumopanama.com'
    };
    return auth.sendPasswordResetEmail(email, actionCodeSettings)
      .then(() => {})
      .catch((error) => console.error(error))
  }

  get isLoggedIn(): boolean {
    const  user  =  JSON.parse(localStorage.getItem('user'));
    return  user  !==  null;
  }

  isEmailVerified() {
    return firebase.auth().currentUser.emailVerified;
  }
}
