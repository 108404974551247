import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { JsMapsProvider } from '../js-maps/js-maps';
declare var google;

@Injectable({
  providedIn: 'root'
})
export class MapsProvider {
  type;
  map: any;

  constructor(public platform: Platform) {
    this.map = new JsMapsProvider();
    this.type = 'javascript';
  }

  init(location, element, zoom){
    return this.map.init(location, element, zoom);
  }

  addMarker(options, animation) {
    let marker = this.map.addMarker(options, animation);
    return marker;
  }

  containsArea(coords) {
    return this.map.containsArea(coords);
  }

  clearMarkers(markers) {
    return new Promise<any>((resolve, reject) => {
      if (this.map.clearMarkers(markers)) {
        resolve(true);
      } else {
        reject(false)
      }
    });
  }

  inRange(point1, point2) : Promise<any> {
    return new Promise((resolve, reject) => {
      if (point1 && point2) {
        const distance = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(point1), new google.maps.LatLng(point2));
        resolve(distance);
      } else {
        reject(false);
      }
    })
  }
}
