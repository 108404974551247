import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { DataService } from '../services/data.service';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-tab2',
  templateUrl: 'tab2.page.html',
  styleUrls: ['tab2.page.scss']
})
export class Tab2Page {
  show: string = 'open';
  initial_items = [];
  visits = [];
  all_items = [];
  filtered = false;

  constructor(
    private router: Router,
    private fb: FirebaseService,
    private dataService: DataService,
    private menu: MenuController
  ) {}

  segmentChanged(event) {
    this.show = event.detail.value;
  }

  goToVisit(visit = null) {
    if (visit) {
      this.dataService.setData(visit.id, visit);
      this.router.navigateByUrl('/tabs/w-visit/' + visit.id);
    }
  }

  ionViewWillEnter() {
    this.loadVisits();
  }

  loadVisits() {
    this.fb.obsCollectionBy('visit', 'placed', true, 'completedDate').subscribe(
      res => {
        this.visits = res;
        this.initial_items = res;
      }
    );
  }

  filterItems(ev: any) {
    let val = ev.target.value;
    if (val && val.trim() != '') {
      this.visits = this.visits.filter(item => {
        return (
          item.customer.toLowerCase().indexOf(val.toLowerCase()) > -1 
        );
      });
      this.filtered = true;
    } else {
      this.visits = this.initial_items;
      this.filtered = false;
    }
  }

  toggleFilter() {
    this.menu.open('end');
  }
  applyFilters() {
    
  }

  loadData(event) {
/*     if (this.filterItemsField) {
      this.requestVisits('visits-admin', {
        collection: 'visit',
        field: this.filterItemsField,
        valuesIn: this.filterItemsIds,
        startAfter: this.currentIndex,
        orderField: "datetime",
        limit: 10,
        placed: this.fPlaced,
        inPlace: this.fInPlace
      }, false);
    } else {
      this.getVisits();
    } */
    setTimeout(() => {
      event.target.complete();
    }, 500);
  }
}
